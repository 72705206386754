import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService, SystemService } from "@/services"
import Loading from '@/mixins/loading.mixin'

export default {
  mixins: [
    Loading
  ],
  props: {
    keyValue: String,
    value: { type: Boolean, default: false }
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemService: null,
    // activators --------------------
    msgOpen: false,
    // general -----------------------
    localItem: [],
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    stepper: 0,
    useQueries: false,
    useQueriesTimeout: 5,
    useQueriesTimeoutCurrent: 0,
    valid: false,
    update: true,
    xmlString: ''
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'chartData', 'chartItem', 'sqlValues'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'database', 'canvas', 'title', 'legend', 'note',
      'axis', 'bar', 'box', 'compare', 'circle', 'line',
      'guideline', 'timeline', 'milestone', 'package_', 'values'
    ]),

    __C_() { return __C.CHART },

    isHelperDisplayed() { return this.chartItem.helper ? true : false },
    isPreviewChart() { return this.preview == 'chart' ? true : false },
    isChartDataAvailable() {  return this.chartData.length === 0 ? false : true },

    chartType() { return this.chartItem.ChartType },
    chartUpdated: {
      get() { return this.$store.state.sysenv.menuUpdated },
      set(value) { this.$store.commit("sysenv/setMenuStatusUpdated", value) }
    },
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit('sysenv/codePropagate', value) }
    },
    modeNew() { return this.formMode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.formMode == __C.FORM.EDIT_MODE_MOD },
    formMode() {
      if (!!this.keyValue) return __C.FORM.EDIT_MODE_MOD
      else return __C.FORM.EDIT_MODE_NEW
    },
    formOpened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    helper: {
      get() { return this.chartItem.helper },
      set(val) { this.setChartItem({ helper: val })}
    },
    preview() { return this.chartItem.preview || 'chart' },
    text() { return this.chartItem.Text || { Text: [], Filter: [], Sql: [] } }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemService = new SystemService()
  },
  mounted() {
    this.checkQueriesUsed()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'setChartData', 'setChartItem', 'setEmpty', 'setSqlValueNote',
      'setSqlValueMilestone'
    ]),


    onAction(action) {
      if (action == 'save') this.modeNew ? this.add() : this.edit()
      else if (action == 'saveas') this.saveas()        
      else if (action == 'delete') this.del()
      else {
        this.$emit('input', false)
        this.$emit('close')
        this.setEmpty()
      }
    },
    onChartType(typeName) {
      if (!typeName) return
      this.setChartItem({ ChartType: typeName })
    },
    onImport(code) {
      let primeData = { 
        ChartNo: this.chartItem.ChartNo,
        PhaseCode: this.chartItem.PhaseCode,
        GroupCode: this.chartItem.GroupCode,
        ItemCode: this.chartItem.ItemCode,
        SubItem: this.chartItem.SubItem
      }
      this.getChart(code).then(() => {
        if(primeData.PhaseCode == 'ALL') return

        // Needs to time-delay to wait for the previous $refs.general.setData
        // process is finished in the getChart(code) process.
        setTimeout(() => {
          this.setChartItem(primeData)
          this.$refs.general.setData(this.formMode)
        }, 500)
      })
    },
    onMoved(attrValues) {
      if(attrValues.type) {
        if(attrValues.type == __C.CHART.MOVED_EL_TYPE_TEXT) {
          let chartProp_ = JSON.parse(JSON.stringify(this.chartItem[attrValues.propName]))
          Object.keys(attrValues.els).forEach(k => { chartProp_[attrValues.subName][attrValues.index][k] = attrValues.els[k] })
          this.setChartItem({ [attrValues.propName]: chartProp_})

        // updated by jihee ----------------------------------------------------------- begin
        } else if(attrValues.type == __C.CHART.MOVED_EL_TYPE_BARSERIES) {
          let chartProp_ = JSON.parse(JSON.stringify(this.chartItem[attrValues.propName]))
          Object.keys(attrValues.els).forEach(k => { chartProp_[attrValues.index][attrValues.key][k] = attrValues.els[k] })
          this.setChartItem({ [attrValues.propName]: chartProp_})
        } else if(attrValues.type == __C.CHART.MOVED_EL_TYPE_LINE) {
          let chartProp_ = JSON.parse(JSON.stringify(this.chartItem[attrValues.propName]))
          Object.keys(attrValues.els).forEach(k => { chartProp_[attrValues.index][k] = attrValues.els[k] })
          this.setChartItem({ [attrValues.propName]: chartProp_})
        }
        // updated by jihee ----------------------------------------------------------- end
      } else {
        Object.keys(attrValues).forEach(k => { this.setChartItem({ [k]: attrValues[k] }) })
      }
    },
    onSave() {
      if(this.modeNew) return
      this.edit()
    },
    onXmlString(xmlString) {
      // this.setChartItem({ XmlString: xmlString })
      this.xmlString = xmlString
    },

    // CRUD Process ### -------------
    add() {
      // 사용여부 체크
      if( ( this.chartItem.ItemCode === 'ALL' || this.chartItem.ItemCode === '' || this.chartItem.ItemCode == undefined ) &
          ( this.chartItem.SubItem === 0 || this.chartItem.SubItem === '' || this.chartItem.SubItem == undefined ) ) {
        this.setChartItem({UsageStatus : 'N' })
      }else{
        this.setChartItem({UsageStatus : 'Y' })
      } 
      let reqItem = { ...JSON.parse(JSON.stringify(this.chartItem)), XmlString: this.xmlString }
      this.putChart(reqItem, res => {
        if(res.NextNo.CHART_NO) {
          this.setChartItem({ 
            Idx: res.NextNo.IDX,
            ChartNo: res.NextNo.CHART_NO 
          })
        }

        this.$emit('key-value-update', res.NextNo.CHART_NO)
        this.$emit('updated', this.chartItem)
        this.chartUpdated = true
      })
    },
    edit() {
      // 사용여부 체크
      if( ( this.chartItem.ItemCode === 'ALL' || this.chartItem.ItemCode === '' || this.chartItem.ItemCode == undefined ) &
          ( this.chartItem.SubItem === 0 || this.chartItem.SubItem === '' || this.chartItem.SubItem == undefined ) ) {
        this.setChartItem({UsageStatus : 'N' })
      }else{
        this.setChartItem({UsageStatus : 'Y' })
      } 
      let reqItem = { ...JSON.parse(JSON.stringify(this.chartItem)), XmlString: this.xmlString }
      this.updChart(reqItem, _ => {
        this.$emit('updated', this.chartItem)
        this.getChart()
      })
    },
    saveas() {
      // 사용여부 체크
      if( ( this.chartItem.ItemCode === 'ALL' || this.chartItem.ItemCode === '' || this.chartItem.ItemCode == undefined ) &
          ( this.chartItem.SubItem === 0 || this.chartItem.SubItem === '' || this.chartItem.SubItem == undefined ) ) {
        this.setChartItem({UsageStatus : 'N' })
      }else{
        this.setChartItem({UsageStatus : 'Y' })
      }
      let reqItem = { ...JSON.parse(JSON.stringify(this.chartItem)), XmlString: this.xmlString }
      this.sasChart(reqItem, res => {
        if(res.NextNo.CHART_NO) {
          this.setChartItem({ 
            Idx: res.NextNo.IDX,
            ChartNo: res.NextNo.CHART_NO 
          })
        }

        this.$emit('key-value-update', res.NextNo.CHART_NO)
        this.$emit('updated', this.chartItem)
        this.chartUpdated = true
        this.onAction('close')
      })
    },
    del() {
      this.yes = () => {
        this.msgOpen = false
        this.delChart(this.chartItem.ChartNo, () => {
          this.onAction('close')
          this.$emit('updated', this.chartItem)
          this.chartUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete this Chart?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    // ### ------------ -------------
    
    // ETC. ... ### -----------------
    checkQueriesUsed() {        
      if(this.useQueriesTimeoutCurrent > this.useQueriesTimeout) return

      this.useQueriesTimeoutCurrent += 1

      // for the use of SVG-Component's Queries property
      setTimeout(() => {
        this.useQueries = (
          typeof this.$refs.chart__ != 'undefined' && 
          typeof this.$refs.chart__.Queries != 'undefined'
        )
        if(!this.useQueries) this.checkQueriesUsed()
        else this.useQueriesTimeoutCurrent = 0
      }, 1000)
    },
    // ------------ -----------------

    resetable() {
      if (!this.chartItem) return false
      if (this.modeNew) {
        return false
      } else {
        return false
      }
    },
  }
}